var role = "";
if (localStorage.getItem("userData") === null) {
  role = "admin";
} else {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  role = getUserData.role;
}
console.log("role rolerolerolerole:>> ", role);
export default [
  {
    path: '/captive',
    name: 'captive-login',
    component: () => import('@/views/apps/captive/connect_wifi.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo/tag/:tag",
    name: "apps-todo-tag",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["team", "low", "medium", "high", "update"].includes(to.params.tag))
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Shop",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/wishlist",
    name: "apps-e-commerce-wishlist",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
      ),
    meta: {
      pageTitle: "Wishlist",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Wishlist",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Checkout",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Checkout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/:slug",
    name: "apps-e-commerce-product-details",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
      ),
    meta: {
      pageTitle: "Product Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
        {
          text: "Product Details",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- hotsport_all ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/hotsport/all/AP",
    name: "hotsport_ap",
    component: () => import("@/views/apps/hotsport_all/hotsport_ap.vue"),
  },
  {
    path: "/apps/hotsport/all/Gateway",
    name: "hotsport_gateway",
    component: () => import("@/views/apps/hotsport_all/hotsport_gateway.vue"),
  },
  // *===============================================---*
  // *--------- VENUE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/venue/list",
    name: "apps-venue-list",
    component: () => import("@/views/apps/venue/venue-list/VenueList.vue"),
  },

  // *===============================================---*
  // *--------- REPORT ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/apps/reports/users",
    name: "apps-reports-users",
    component: () => import("@/views/apps/reports/Users.vue"),
  },

  {
    path: "/apps/reports/impressions",
    name: "apps-reports-impressions",
    component: () => import("@/views/apps/reports/Impressions.vue"),
  },
  {
    path: "/reports/noc",
    name: "report-noc",
    component: () => import("@/views/apps/reports_noc/noc.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/reports/ap",
    name: "report-noc",
    component: () => import("@/views/apps/reports_noc/ap.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/reports/gateway",
    name: "report-noc",
    component: () => import("@/views/apps/reports_noc/gateway.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/venue/reports",
    name: "venue-reports",
    component: () => import("@/views/apps/reports/venue_reports.vue"),
    // component: () => import("@/views/apps/reports/noc.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/sum-venue/reports",
    name: "venue-reports",
    component: () => import("@/views/apps/reports/report_vennue.vue"),
    // component: () => import("@/views/apps/reports/noc.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/dev/reports",
    name: "dev-reports",
    component: () => import("@/views/apps/reports/report_dev.vue"),
    // component: () => import("@/views/apps/reports/noc.vue"),
    meta: {
      layout: 'full',
    },
  },
  {
    path: "/ap/reports",
    name: "ap-reports",
    component: () => import("@/views/apps/reports/ap_reports.vue"),
    // component: () => import("@/views/apps/reports/noc.vue"),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: "/ap/ap_reports_detail",
    name: "ap-reports-detail",
    component: () => import("@/views/apps/reports/ap_reports_detail.vue"),
    // component: () => import("@/views/apps/reports/noc.vue"),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  


  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/chat/message.vue"),
    meta: {
      layout: 'full',
    },
  },

  {
    path: "/apps/reports/connections",
    name: "apps-reports-connections",
    component: () => import("@/views/apps/reports/Connections.vue"),
  },

  {
    path: "/apps/reports/traffic",
    name: "apps-reports-traffic",
    component: () => import("@/views/apps/reports/Traffic.vue"),
  },
  {
    path: "/apps/reports/users-use",
    name: "apps-reports-users_use",
    component: () => import("@/views/apps/reports/users_use.vue"),
  },

  {
    path: "/apps/reports/people",
    name: "apps-reports-people",
    component: () => import("@/views/apps/reports/People.vue"),
    beforeEnter(to, _, next) {
      if (role === "admin") next();
      else next({ name: "game-campaign" });
    },
  },

  {
    path: "/venue/reports/users",
    name: "venue-reports-users",
    component: () => import("@/views/apps/venuereports/Users.vue"),
    meta: {
      layout: "partners",
    },
  },

  {
    path: "/venue/reports/impressions",
    name: "venue-reports-impressions",
    component: () => import("@/views/apps/venuereports/Impressions.vue"),
    meta: {
      layout: "partners",
    },
  },

  {
    path: "/venue/reports/connections",
    name: "venue-reports-connections",
    component: () => import("@/views/apps/venuereports/Connections.vue"),
    meta: {
      layout: "partners",
    },
  },

  {
    path: "/venue/reports/traffic",
    name: "venue-reports-traffic",
    component: () => import("@/views/apps/venuereports/Traffic.vue"),
    meta: {
      layout: "partners",
    },
  },

  {
    path: "/venue/reports/people",
    name: "venue-reports-people",
    component: () => import("@/views/apps/venuereports/People.vue"),
    meta: {
      layout: "partners",
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },

  // Invoice
  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoiceList.vue"),
  },
  {
    path: "/apps/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/apps/invoice/add/",
    name: "apps-invoice-add",
    component: () => import("@/views/apps/invoice/invoice-add/InvoiceAdd.vue"),
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoice-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"),
  },

  // *===============================================---*
  // *--------- ADD CODE ---- ---------------------------------------*
  // *===============================================---*

  // {
  //   path: '/apps/add-code/list',
  //   name: 'add-code',
  //   component: () => import('@/views/apps/add-code/AddCodeList.vue'),
  // },
  {
    path: "/apps/add-code/list/hour-code",
    name: "list-hour-code",
    component: () => import("@/views/apps/add-code/hour-code/HourCodeList.vue"),
  },
  // --------------------------------------------------
  {
    path: "/apps/add-code/list/date-time-code",
    name: "list-date-time-code",
    component: () =>
      import("@/views/apps/add-code/date-time-code/DateTimeCodeList.vue"),
  },
  {
    path: "/apps/add-code/add/add-location",
    name: "add-coed-map",
    component: () => import("@/views/apps/add-code/addTag.vue"),
  },
  {
    path: "/apps/noc/Noc_list",
    name: "noc-data",
    component: () => import("@/views/apps/noc/Noc_all.vue"),
  },
  // --------------------------------------------------
  {
    path: "/apps/festival",
    name: "festival",
    component: () => import("@/views/apps/festival/festival.vue"),
  },
  {
    path: "/apps/content",
    name: "content",
    component: () => import("@/views/apps/content/content.vue"),
  },

  
  // --------------------------User Game------------------------
  {
    path: "/game/campaign",
    name: "game-campaign",
    component: () =>
      import("@/views/apps/add-code/date-time-code/DateTimeCodeList.vue"),
    meta: {
      layout: "usergame",
    },
  },
  {
    path: "/game/content",
    name: "game-content",
    component: () => import("@/views/apps/content/content.vue"),

    meta: {
      layout: "usergame",
    },
  },
  {
    path: "/game/Top10",
    name: "game-Top10",
    component: () => import("@/views/apps/content/gamesTop10.vue"),

    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/dashboard",
    name: "game-dashboard",
    component: () => import("@/views/apps/reports/People.vue"),
    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/reports/users",
    name: "game-reports-users",
    component: () => import("@/views/apps/reports/Users.vue"),
    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/reports/impressions",
    name: "game-reports-impressions",
    component: () => import("@/views/apps/reports/Impressions.vue"),
    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/reports/connections",
    name: "game-reports-connections",
    component: () => import("@/views/apps/reports/Connections.vue"),
    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/reports/traffic",
    name: "game-reports-traffic",
    component: () => import("@/views/apps/reports/Traffic.vue"),
    meta: {
      layout: "usergame",
    },
  },

  {
    path: "/game/reports/people",
    name: "game-reports-people",
    component: () => import("@/views/apps/reports/People.vue"),
    meta: {
      layout: "usergame",
    },
  },
  {
    path: "/nt/reports/people",
    name: "nt-reports-people",
    component: () => import("@/views/apps/reports/People.vue"),
    meta: {
      layout: 'NT',
    },
  },
];
