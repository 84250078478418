export default [
  {
    path: '/partners/calendar',
    name: 'partners-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'partners',
    },
  },
 {
    path: '/partners/gateway',
    name: 'partners-gateway',
    component: () => import('@/views/partners/gateway/gateway-list/GatewayList.vue'),
    meta: {
      layout: 'partners',
    },
  },



]
